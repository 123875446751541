<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M33.0704 12.4138C32.4782 12.4138 31.9172 12.5517 31.4152 12.7926V11.8621C31.4152 9.73241 29.6828 8 27.5531 8C25.8372 8 24.3807 9.12552 23.8786 10.6759C23.3306 10.3779 22.7035 10.2069 22.0359 10.2069C19.9062 10.2069 18.1738 11.9393 18.1738 14.069V24.4708L17.2175 22.8138C17.2138 22.8083 17.2101 22.7991 17.2064 22.7936C16.6897 21.8998 15.8566 21.2616 14.8598 20.9968C13.863 20.7301 12.8221 20.868 11.9301 21.3848C11.0363 21.9016 10.3982 22.7347 10.1333 23.7315C9.87035 24.7117 9.99909 25.7361 10.4956 26.6189C14.9701 36.0533 17.9531 40 24.7945 40C31.4869 40 36.9324 34.5545 36.9324 27.8621V16.2759C36.9324 14.1462 35.2 12.4138 33.0704 12.4138ZM34.7255 27.8621C34.7255 33.3389 30.2713 37.7931 24.7945 37.7931C19.5825 37.7931 17.0501 35.2938 12.4726 25.6368C12.4598 25.6092 12.4451 25.5834 12.4304 25.5559C12.2097 25.1733 12.1508 24.7264 12.2648 24.2998C12.3789 23.8731 12.6529 23.5163 13.0354 23.2938C13.4179 23.0731 13.8648 23.0124 14.2915 23.1283C14.7108 23.2405 15.0639 23.5071 15.2956 23.8952C15.2975 23.897 15.2993 23.9007 15.2993 23.9025L18.3246 29.1402C18.5747 29.5724 19.0841 29.7821 19.566 29.6552C20.0478 29.5264 20.3844 29.0887 20.3844 28.5885V14.069C20.3844 13.1568 21.1274 12.4138 22.0395 12.4138C22.9517 12.4138 23.6947 13.1568 23.6947 14.069V23.4483C23.6947 24.057 24.1894 24.5517 24.7982 24.5517C25.4069 24.5517 25.9016 24.057 25.9016 23.4483V14.069V11.8621C25.9016 10.9499 26.6446 10.2069 27.5568 10.2069C28.469 10.2069 29.212 10.9499 29.212 11.8621V16.2759V23.4483C29.212 24.057 29.7067 24.5517 30.3154 24.5517C30.9241 24.5517 31.4189 24.057 31.4189 23.4483V16.2759C31.4189 15.3637 32.1618 14.6207 33.074 14.6207C33.9862 14.6207 34.7292 15.3637 34.7292 16.2759V27.8621H34.7255Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-hand-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
